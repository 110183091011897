import { createReducer, on } from '@ngrx/store';
import { Feedback } from 'src/core/interfaces/feedback.interface';
import { Option } from 'src/core/interfaces/option.interface';
import { FeedbackActions, removeAllFeedbacks } from './feedback.actions';

export interface FeedbackState {
  feedback: Feedback;
  recipients: Option[];
  requestAbout: Option;
}

const initialState: FeedbackState = {
  feedback: null,
  recipients: [],
  requestAbout: null,
};

export const feedbackReducer = createReducer(
  initialState,

  on(FeedbackActions.setFeedbackFormData, (state, { feedback }) => ({
    ...state,
    feedback,
  })),

  on(FeedbackActions.updateFeedbackFormData, (state, { field, value }) => ({
    ...state,
    feedback: {
      ...state.feedback,
      [field]: value,
    },
  })),

  on(FeedbackActions.setRecipients, (state, { users }) => ({
    ...state,
    recipients: users,
  })),

  on(FeedbackActions.setRequestAbout, (state, { aboutUser }) => ({
    ...state,
    requestAbout: aboutUser,
  })),

  on(removeAllFeedbacks, () => initialState),
);
