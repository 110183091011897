import { createReducer, on } from '@ngrx/store';
import { LayoutState } from './layout-action.types';
import { closeSideNavBar, toggleCareerPathBar, toggleSideNavBar } from './layout.actions';

const initialState: LayoutState = {
  isNavbarOpen: false,
  isCareerPathOpen: false,
};

export const layoutReducer = createReducer(
  initialState,

  on(toggleSideNavBar, (state) => ({
    ...state,
    isNavbarOpen: !state.isNavbarOpen,
  })),

  on(closeSideNavBar, (state) => ({
    ...state,
    isNavbarOpen: false
  })),

  on(toggleCareerPathBar, (state) => ({
    ...state,
    isCareerPathOpen: !state.isCareerPathOpen,
  })),
);
