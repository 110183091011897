import { createReducer, on } from '@ngrx/store';
import { UserGoal } from 'src/core/interfaces/user/goal.interface';
import {
  ActionItemActions,
  GoalsActions,
  removeAllGoals,
  SubtaskActions,
} from './goals.actions';

const initialState: UserGoal[] = [];

export const goalsReducer = createReducer(
  initialState,

  /* DB actions */
  on(GoalsActions.loadGoalsFromDB, (state, { goals }) => {
    const newGoals: UserGoal[] = [];
    goals.forEach((goal) => {
      if (!state.find((one) => one._id === goal._id)) {
        newGoals.push(goal);
      }
    });

    return [...state, ...newGoals].sort(
      (a, b) =>
        new Date(b.ISOStartDate).getTime() - new Date(a.ISOStartDate).getTime(),
    );
  }),

  on(GoalsActions.addGoal, (state, { goal }) => {
    const goalFound = state.find((one) => one._id === goal._id);

    if (goalFound) {
      return state;
    }

    return [...state, goal].sort(
      (a, b) =>
        new Date(b.ISOStartDate).getTime() - new Date(a.ISOStartDate).getTime(),
    );
  }),
  on(GoalsActions.loadGoals, (state, { goals }) =>
    [...state, ...goals].sort(
      (a, b) =>
        new Date(b.ISOStartDate).getTime() - new Date(a.ISOStartDate).getTime(),
    ),
  ),
  on(GoalsActions.updateGoal, (state, { updatedGoal }) =>
    state.map((goal) => {
      if (goal._id === updatedGoal._id) {
        return updatedGoal;
      }

      return goal;
    }),
  ),
  on(GoalsActions.updateField, (state, { entityId, updatedField }) =>
    state.map((goal) => {
      if (goal._id === entityId) {
        return {
          ...goal,
          [updatedField.field]: updatedField.value,
        };
      }

      return goal;
    }),
  ),
  on(GoalsActions.deleteEntity, (state, { entityId }) =>
    state.filter((goal) => goal._id !== entityId),
  ),

  /* Action Items */
  on(ActionItemActions.createOne, (state, { actionItem, goalId }) =>
    state.map((goal) => {
      if (goal._id === goalId) {
        goal = {
          ...goal,
          actionItems: [...goal.actionItems, actionItem],
        };
      }

      return goal;
    }),
  ),
  on(ActionItemActions.updateOne, (state, { actionItem, goalId }) =>
    state.map((goal) => {
      if (goal._id === goalId) {
        goal = {
          ...goal,
          actionItems: goal.actionItems.map((item) => {
            if (item._id && item._id === actionItem._id) {
              return actionItem;
            }

            return item;
          }),
        };
      }

      return goal;
    }),
  ),
  on(ActionItemActions.removeOne, (state, { actionItemId, goalId }) => {
    return state.map((goal) => {
      if (goal._id === goalId) {
        goal = {
          ...goal,
          actionItems: goal.actionItems.filter(
            (actionItem) => actionItem._id !== actionItemId,
          ),
        };
      }

      return goal;
    });
  }),

  /* Subtasks */
  on(SubtaskActions.add, (state, { goalId, actionItemId, subtask }) =>
    state.map((goal) => {
      if (goal._id !== goalId) {
        return goal;
      }

      for (let index = 0; index < goal.actionItems.length; index++) {
        const actionItem = goal.actionItems[index];

        if (actionItem.title !== actionItemId) {
          continue;
        }

        return {
          ...goal,
          actionItems: goal.actionItems.map((item) =>
            item.title !== actionItem.title
              ? item
              : {
                  ...actionItem,
                  folded: false,
                  subtasks: [
                    {
                      ...subtask,
                      done: false,
                    },
                    ...(actionItem.subtasks || []),
                  ],
                },
          ),
        };
      }

      return goal;
    }),
  ),
  on(
    SubtaskActions.updateField,
    (state, { goalId, actionItemId, _id, field, value }) =>
      state.map((goal) => {
        if (goal._id !== goalId) {
          return goal;
        }

        for (let index = 0; index < goal.actionItems.length; index++) {
          const actionItem = goal.actionItems[index];

          if (actionItem.title !== actionItemId) {
            continue;
          }

          const subtask = actionItem.subtasks.find(
            (subtask) => subtask._id === _id,
          );

          return {
            ...goal,
            actionItems: goal.actionItems.map((action) =>
              action.title !== actionItem.title
                ? action
                : {
                    ...actionItem,
                    folded: false,
                    subtasks: actionItem.subtasks.map((task) =>
                      task._id !== subtask._id
                        ? task
                        : { ...subtask, [field]: value },
                    ),
                  },
            ),
          };
        }

        return goal;
      }),
  ),
  on(SubtaskActions.delete, (state, { actionItemId, goalId, _id }) =>
    state.map((goal) => {
      if (goal._id !== goalId) {
        return goal;
      }

      for (const actionItem of goal.actionItems) {
        if (actionItem.title !== actionItemId) {
          continue;
        }

        const subtasks = actionItem.subtasks.filter(
          (subtask) => subtask._id !== _id,
        );

        return {
          ...goal,
          actionItems: goal.actionItems.map((action) =>
            action.title !== actionItem.title
              ? action
              : {
                  ...actionItem,
                  folded: false,
                  subtasks,
                },
          ),
        };
      }

      return goal;
    }),
  ),

  /* Skills */
  on(GoalsActions.addSkills, (state, { goalId, skills }) =>
    state.map((goal) =>
      goal._id === goalId
        ? goal
        : { ...goal, skills: [...(goal.skills || []), ...skills] },
    ),
  ),

  /* Resources */
  on(GoalsActions.addResources, (state, { entityId, resources }) =>
    state.map((goal) =>
      goal._id !== entityId ? goal : { ...goal, resources },
    ),
  ),
  on(GoalsActions.removeResource, (state, { entityId, resourceId }) =>
    state.map((entity) =>
      entity._id !== entityId
        ? entity
        : {
            ...entity,
            resources: entity.resources.filter(
              (resource) => resource._id !== resourceId,
            ),
          },
    ),
  ),

  /* Clear store */
  on(removeAllGoals, () => initialState),
);
